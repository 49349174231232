import { OrgId, OrgRegistrationQuestionId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function orgRegistrationQuestionGrouping__client__delete(p: { id: OrgRegistrationQuestionId; orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const linkedPackages = await h.OrgRegistrationPackage.query({
    where: [{ questionGroupingIds: { [p.id]: { exists: ["==", true] } } }, { orgId: ["==", p.orgId] }]
  }).then(a => a.docs);

  await h._BatchRunner.executeBatch([
    await h.OrgRegistrationQuestionGrouping.delete(
      {
        id: p.id
      },
      { returnBatchTask: true }
    ),
    ...(await Promise.all(
      linkedPackages.map(pkg => {
        return h.OrgRegistrationPackage.update(
          { id: pkg.id, doc: { questionGroupingIds: { [p.id]: h._MagicDeleteValue } } },
          { returnBatchTask: true }
        );
      })
    ))
  ]);
}
