import { OrgRegistrationQuestion, OrgRegistrationQuestionId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function orgRegistrationQuestionGrouping__client__update(p: {
  id: OrgRegistrationQuestionId;
  shallowChanges: Partial<OrgRegistrationQuestion>;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  h.OrgRegistrationQuestionGrouping.updateShallow({ id: p.id, doc: p.shallowChanges });
}
