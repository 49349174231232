import { OrgRegistrationQuestion, OrgRegistrationQuestionGrouping } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function orgRegistrationQuestionGrouping__client__add(p: {
  doc: Omit<OrgRegistrationQuestionGrouping, "id" | "createdAtMS">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.OrgRegistrationQuestionGrouping.add({
    doc: {
      createdAtMS: Date.now(),
      ...p.doc
    }
  });
}

// i18n certified - complete
